<template>
    <div class="page-main">
        <div class="main-banner">
            <img src="../../assets/image/main-page/cooperation_banner.jpg" alt="">
            <div class="banner-text">
                <p class="tit">商务合作</p>
                <p class="tit-en">Business cooperation</p>
            </div>
        </div>
        <div class="module-single">
            <div class="module-main">
                <div class="module-tit-group">
                    <p class="tit">商务合作</p>
                    <p class="tit-en">Business cooperation</p>
                    <span class="line"></span>
                </div>
                <div class="cooperation-group">
                    <div class="tit-group">
                        <p class="tit">成都菠萝蜜多信息科技有限公司</p>
                        <p class="describe">生产资料行业必备的集商流、信息流、物流、资金流于一体的工业品电商平台</p>
                    </div>
                    <ul class="cooperation-type">
                        <li>
                            <div class="icon">
                                <img src="../../assets/image/main-page/relation_icon1.png" alt="">
                            </div>
                            <p class="tit">公司地址</p>
                            <p class="describe">四川省成都市龙泉驿区洪安镇龙洪路9号西部化工市场综合楼2层12号</p>
                        </li>
                        <li>
                            <div class="icon">
                                <img src="../../assets/image/main-page/relation_icon2.png" alt="">
                            </div>
                            <p class="tit">联系方式</p>
                            <p class="describe">甜甜：19102661487</p>
                            <p class="describe">侯女士：19113171092</p>
                        </li>
                        <li>
                            <div class="icon">
                                <img src="../../assets/image/main-page/relation_icon3.png" alt="">
                            </div>
                            <p class="tit">公司邮箱</p>
                            <p class="describe">OP@chinachem.cn</p>
                            <p class="describe">我们将在收到邮件后的3个工作日内给您答复</p>
                        </li>
                        <li>
                            <div class="icon">
                                <img src="../../assets/image/main-page/relation_icon4.png" alt="">
                            </div>
                            <p class="tit">客服QQ</p>
                            <p class="describe">客服甜甜：3105714127</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "cooperation-page"
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/main-module";
    .cooperation-group{
        margin-top: 64px;
        .tit-group{
            text-align: center;
            .tit{
                font-size: 20px;
                color: #333;
                font-weight: 700;
                line-height: 26px;
            }
            .describe{
                font-size: 14px;
                color: #999;
                margin-top: 8px;
                line-height: 20px;
            }
        }
        .cooperation-type{
            @include flex-between;
            margin-top: 30px;
            li{
                width: 284px;
                height: 300px;
                background: #f5f5f5;
                padding: 29px 16px 0;
                box-sizing: border-box;
                text-align: center;
                .icon{
                    width: 100px;
                    height: 100px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .tit{
                    font-size: 18px;
                    color: #333;
                    line-height: 24px;
                    margin: 21px 0;
                }
                .describe{
                    line-height: 28px;
                    font-size: 14px;
                    color: #666;
                }
            }
        }
    }
</style>
